/**
 * dev utils to make local development easier (dynamically-imported mock data,
 * dev-only console logs, etc.)
 */

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

export const isTest = () => process.env.NODE_ENV === 'test';

export const devLog = (string) => {
  if (isDev() || window?.StravaDevTools?.enabled) {
    // eslint-disable-next-line no-console
    console.log(string);
  }
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default { isDev, devLog, timeout };
