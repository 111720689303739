import * as Sentry from '@sentry/browser';
import { matchMaxMedia } from '@strava/ui/MediaQuery';
import { devLog, isDev } from './devUtils';
import {
  hasExtensionReferenceInBreadCrumbs,
  hasExtensionReferenceInHintErrorStack,
  hasExtensionReferenceInEventErrorStack,
  isXhrFalsePositive,
  isMfeChunkLoadError,
  groupErrors,
  transactionName
} from './sentry.helper';

const displayType = () => {
  if (matchMaxMedia?.screenSm()) {
    return 'phone';
  }
  if (matchMaxMedia?.screenMd()) {
    return 'tablet';
  }
  return 'desktop';
};

if (!window.StravaSentryDsn) {
  window.StravaSentryDsn = {};
}
if (!window.StravaSentryDsn.deferred) {
  window.StravaSentryDsn.deferred = {};
  const promise = new Promise(
    function _(resolve, reject) {
      this.doResolve = resolve;
      this.doReject = reject;
    }.bind(window.StravaSentryDsn.deferred)
  );
  window.StravaSentryDsn.deferred.then = promise.then.bind(promise);
}

const {
  enabled,
  environment,
  release,
  userId,
  dsn,
  debug,
  pageProperties,
  mobileOS,
  isMobile
} = window.StravaSentry || {};

devLog(
  `Logged-out page experiment ::: ${JSON.stringify(
    pageProperties?.experiment_info || {}
  )}`
);

window.StravaSentryDsn.deferred.doResolve(dsn);

window.StravaSentryDsn.deferred.then((_dsn) => {
  // configure sentry_frontend.yml to enable sentry on local
  if (enabled) {
    Sentry.init({
      dsn: _dsn,
      maxBreadcrumbs: 50,
      integrations: [
        new Sentry.BrowserTracing({
          tracingOrigins: [
            'https://www.staging.strava.com',
            'https://www.strava.com',
            'localhost',
            /^\//
          ],
          beforeNavigate: (context) => {
            return {
              ...context,
              name: transactionName()
            };
          }
        })
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i
      ],
      sampleRate: 0.1, 
      debug,
      attachStacktrace: true,
      release,
      initialScope: {
        user: { id: userId }
      },
      environment,
      beforeSend: (event, hint) => {
        if (
          isMfeChunkLoadError(hint) ||
          isXhrFalsePositive(hint) || // safari abort - status 0
          hasExtensionReferenceInHintErrorStack(hint) ||
          hasExtensionReferenceInBreadCrumbs(event) ||
          hasExtensionReferenceInEventErrorStack(event) ||
          hint?.originalException === 'Timeout' // google-recaptcha flakiness
        ) {
          return null;
        }
        groupErrors(event);
        return event;
      },
      /**
       * There is a bug in Safari, that causes `AbortError` when fetch is
       * aborted, and you are in the middle of reading the response. In Chrome
       * and other browsers, it is handled gracefully, where in Safari, it
       * produces additional error, that is jumping outside of the original
       * Promise chain and bubbles up to the `unhandledRejection` handler, that
       * we then captures as error.
       *
       * Ref: https://bugs.webkit.org/show_bug.cgi?id=215771
       */
      ignoreErrors: ['AbortError: Fetch is aborted']
    });

    Sentry.setTag('strava_device.mobile_os', mobileOS);
    Sentry.setTag('strava_device.is_mobile', isMobile);
    Sentry.setTag('strava_device.display_type', displayType());

    if (pageProperties) {
      const experiment = pageProperties?.experiment_info || {};
      const {
        experiment_cohort: cohort,
        experiment_name: name,
        experiment_forced: forced
      } = experiment;
      if (cohort && name) {
        Sentry.setTag('experiment.name', name);
        Sentry.setTag('experiment.cohort', cohort);
        Sentry.setTag('experiment.forced', forced);
      }
    }
  }
});

/** @type {import("@sentry/browser").withScope} */
export const withScope = (...args) => Sentry.withScope(...args);

/** @type {import("@sentry/browser").setTag} */
export const setTag = (...args) =>
  isDev()
    ? devLog(`Sentry.setTag ::: ${JSON.stringify(args)}`)
    : Sentry.setTag(...args);

/** @type {import("@sentry/browser").setTags} */
export const setTags = (...args) =>
  isDev()
    ? devLog(`Sentry.setTags ::: ${JSON.stringify(args)}`)
    : Sentry.setTags(...args);

/** @type {import("@sentry/browser").setContext} */
export const setContext = (...args) =>
  isDev()
    ? devLog(`Sentry.setContext ::: ${JSON.stringify(args)}`)
    : Sentry.setContext(...args);

/** @type {import("@sentry/browser").captureException} */
export const logError = (error, captureContext) => {
  if (isDev()) {
    // eslint-disable-next-line no-console
    console.error('Sentry.logError :::', error, captureContext);
  } else {
    Sentry.captureException(error, captureContext);
  }
};

/** @type {import("@sentry/browser").captureMessage} */
export const logMessage = (message, captureContext) => {
  if (isDev()) {
    // eslint-disable-next-line no-console
    console.error('Sentry.logMessage :::', message, captureContext);
  } else {
    Sentry.captureMessage(message, captureContext);
  }
};

export default { logError, logMessage, withScope, setTag, setTags, setContext };
