import * as Sentry from '@sentry/browser';

const CHROME_BROWSER_EXTENSION = '-extension://';
const SAFARI_BROWSER_EXTENSION = '@webkit-masked-url';
const ANONYMOUS = '<anonymous>';
const DOWNLOADED_FILES = ['/Users/', '/users/']; // eg. https://sentry.io/share/issue/391b31fba7714147a99a3697db116840/

// see https://github.com/thomaschampagne/elevate/blob/fc5772a12327c0d5015fb0fec64aba1cec6756c7/webextension/scripts/processors/activities-synchronize.ts
const ELEVATE_APP_CONSOLE_LOGS = [
  'Fast sync mode enabled',
  'A previous sync exists on',
  'local and remote activities count matches',
  'Mismatch found between local and remote activities. Syncing first page only.',
  'Activities fetched in group',
  ', raw activities length: ',
  'PagesRidden] Resolving with ',
  'clearSyncCache requested',
  'Group handled count: ',
  'Updating synced activities to extension local storage.',
  'Activities fetched in group'
];

export const includes = (substringList, string) =>
  !!substringList.find((substring) => {
    return string?.includes(substring);
  });

export const hasExtensionReferenceInHintErrorStack = (hint) =>
  hint?.originalException?.stack?.includes(CHROME_BROWSER_EXTENSION) ||
  hint?.originalException?.stack?.includes(SAFARI_BROWSER_EXTENSION);

export const hasExtensionReferenceInBreadCrumbs = (event) =>
  !!event?.breadcrumbs?.find((entry) => {
    return (
      entry.data?.url?.includes(CHROME_BROWSER_EXTENSION) ||
      entry.data?.url?.includes(SAFARI_BROWSER_EXTENSION) ||
      includes(ELEVATE_APP_CONSOLE_LOGS, entry.message)
    );
  });

export const hasExtensionReferenceInEventErrorStack = (event) => {
  return !!event?.exception?.values?.find((entry) => {
    return !!entry.stacktrace?.frames?.find(
      (frame) =>
        frame?.filename?.includes(CHROME_BROWSER_EXTENSION) ||
        frame?.abs_path?.includes(CHROME_BROWSER_EXTENSION) ||
        frame?.filename?.includes(SAFARI_BROWSER_EXTENSION) ||
        frame?.abs_path?.includes(SAFARI_BROWSER_EXTENSION) ||
        frame?.filename?.includes(ANONYMOUS) ||
        frame?.abs_path?.includes(ANONYMOUS) ||
        includes(DOWNLOADED_FILES, frame?.filename) ||
        includes(DOWNLOADED_FILES, frame?.abs_path)
    );
  });
};

/**
 * There is a bug in Safari, that causes `AbortError` when fetch is
 * aborted, and you are in the middle of reading the response. In Chrome
 * and other browsers, it is handled gracefully, where in Safari, it
 * produces additional error, that is jumping outside of the original
 * Promise chain and bubbles up to the `unhandledRejection` handler, that
 * we then captures as error.
 */
export const isXhrFalsePositive = (hint) => {
  // eslint-disable-next-line no-underscore-dangle
  return hint?.originalException?.request?.__sentry_xhr__?.status_code === 0;
};

// ignore chunkloaderrors originating from MFE
// these typically show up when an MFE is federated
export const isMfeChunkLoadError = (hint) => {
  return (
    hint?.originalException?.name === 'ChunkLoadError' &&
    hint?.originalException?.request.includes('web-assets.strava.com')
  );
};

export const groupErrors = (event) => {
  if (event?.request?.url) {
    /**
     * Groups events matching /activity/<id>?<params> eg.
     * /activities/1
     * /activities/1?param=true
     */
    const regex = new RegExp(/\/activities\/\d+/g);
    const url = new URL(event.request.url);
    const { origin, pathname, search } = url;
    if (regex.test(pathname)) {
      // eslint-disable-next-line no-param-reassign
      event.request.url = `${origin}/activities/id`;

      const activityId = pathname.substring(pathname.lastIndexOf('/') + 1);

      Sentry.setTag('strava.url.activityId', activityId);
      if (search) {
        Sentry.setTag('strava.url.search', search);
      }
    }
  }
};

export const transactionName = () => {
  const { pathname } = window.location;

  if (pathname.includes('/activities/') && pathname.includes('/embed/')) {
    return '/activities/<identifier>/embed/<identifier>';
  }
  if (pathname.includes('/clubs/') && pathname.includes('/latest-rides/')) {
    return '/clubs/<identifier>/latest-rides/<identifier>';
  }
  if (pathname.includes('/athletes/') && pathname.includes('/latest-rides/')) {
    return '/athletes/<identifier>/latest-rides/<identifier>';
  }
  if (pathname.includes('/compare/') && pathname.includes('/segments/')) {
    return '/segments/<identifier>/compare/<identifier>';
  }

  if (pathname.includes('/challenges/')) {
    return '/challenges/<identifier>';
  }
  if (pathname.includes('/clubs/')) {
    return '/clubs/<identifier>';
  }
  if (pathname.includes('/beacon/')) {
    return '/beacon/<identifier>';
  }

  if (pathname.includes('/pros/')) {
    return '/pros/<identifier>';
  }
  if (pathname.includes('/location/')) {
    return '/location/<identifier>';
  }

  // temporarily fixes <<unparameterized>> grouping as recommended by sentry engineers
  // this unblocks analytics performance monitoring until sentry fixes an issue
  // with transaction grouping on their end. 
  // ref - https://develop.sentry.dev/transaction-clustering/
  if (pathname === '/') {
    return '/';
  }
  if (pathname === '/login') {
    return '/login';
  }
  if (pathname === '/register' || pathname === '/register/free') {
    return '/register';
  }
  if (pathname === '/dashboard') {
    return '/dashboard';
  }
  if (pathname.includes('/onboarding/continue') ){
    return '/onboarding/continue';
  }

  return pathname.replace(/\/\d+/g, '/<digits>');
};

export default {
  hasExtensionReferenceInHintErrorStack,
  hasExtensionReferenceInBreadCrumbs,
  hasExtensionReferenceInEventErrorStack,
  includes,
  isXhrFalsePositive,
  isMfeChunkLoadError,
  groupErrors,
  transactionName
};
